<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/shopify_36.svg"
            headerText="Setup Your Shopify Collect"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            @finish="createCollect"
            ref="tabbedForm"
            :update="updateCollect"
            :details="isDetails"
            :dirty="dirty"
            :fetched="fetched"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-1-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="shopifyError"
                            :state="shopifyError.state"
                            :invalid="shopifyError.message"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

export default {
    data() {
        return {
            collectForm: {
                name: '',
                tokenId: '',
            },
            workflowForm: {
                historicalDateRange: 30,
            },
            currentCollectForm: {},
            shopifyError: {
                state: true,
                message: null,
            },
        }
    },
    components: {
        Feedback,
        Loading,
        WitTabbedForm,
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        collectForm: {
            name: {required},
            tokenId: {required},
        },
        workflowForm: {
            historicalDateRange: {},
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            tokens: 'token/shopifyTokens',
        }),
        formId() {
            return this.isDetails ? 'shopify-create-form' : 'shopify-details-form'
        },
        steps() {
            return [
                {
                    name: 'Shopify Settings',
                    invalid: this.$v.collectForm.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.collectForm.name.$model,
                            model: this.$v.collectForm.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'token',
                            value: this.$v.collectForm.tokenId.$model,
                            model: this.$v.collectForm.tokenId,
                            type: 'shopify-token',
                            inputLabel: 'Select Shopify token',
                            disabled: this.isDetails,
                        },
                    ],
                },
                this.isDetails
                    ? null
                    : {
                          name: 'Data Collection Settings',
                          invalid: this.$v.$invalid,
                          inputs: [
                              {
                                  name: 'range',
                                  value: this.$v.workflowForm.historicalDateRange.$model,
                                  model: this.$v.workflowForm.historicalDateRange,
                                  type: 'range',
                                  inputLabel: 'Collect data for last (days)',
                                  min: 0,
                                  max: 365,
                              },
                          ],
                      },
            ].filter(Boolean)
        },
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.isDetails
        },
        dirty() {
            const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
            return Object.keys(collectFormDirty).length > 0
        },
        advertisers() {
            if (this.collectForm.tokenId) {
                const token = this.tokens.find(el => el.id === this.collectForm.tokenId)
                return token.token.advertisers
                    ? token.token.advertisers.map(({Id, Name}) => ({id: Id, label: Name}))
                    : []
            }
            return []
        },
    },
    async created() {
        if (!this.isDetails) {
            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        } else {
            this.fetchCollect()
        }
    },
    methods: {
        async fetchCollect() {
            this.$store.commit('loading/PROCESSING', `Fetching Collect Details...`)

            const {id} = this.$route.params
            const res = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/collect/shopify/${id}`)

            this.collect = res.data.data

            this.collectForm = {
                name: this.collect.name,
                tokenId: this.collect.tokenRef ? this.collect.tokenRef.id : null,
            }
            this.currentCollectForm = Object.assign({}, this.collectForm)
            this.$v.$reset()
            this.fetched = true

            this.$store.commit('loading/PROCESSED')
        },

        createCollect(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                this.shopifyError = {}

                const data = {
                    name: this.collectForm.name,
                    tokenId: this.collectForm.tokenId,
                    projectId: this.$store.state.project.active.id,
                    ...this.workflowForm,
                }

                this.$store.commit('loading/PROCESSING', `Creating Collect...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/collect/shopify`, data)
                    .then(response => {
                        this.$store.commit('loading/PROCESSED')
                        this.shopifyError = {}
                        this.resetForm()

                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        formSessionMap[this.$route.path] = null
                        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                        this.$projectRouter.push(this.redirectUrl)
                    })
                    .catch(exception => {
                        this.$store.commit('loading/PROCESSED')
                        this.shopifyError.state = false
                        this.shopifyError.message = exception.response.data.additionalInfo
                    })
            }
        },

        async updateCollect() {
            if (!this.$v.$invalid) {
                if (this.dirty) {
                    this.$store.commit('loading/PROCESSING', `Updating...`)
                    const {name, currency, advertisers} = filterDirty(this.collectForm, this.currentCollectForm)

                    const data = {
                        name: name ? name : undefined,
                    }

                    try {
                        await this.axios.put(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/shopify/${this.$route.params.id}`,
                            data
                        )

                        this.shopifyError = {}
                        this.currentCollectForm = Object.assign({}, this.collectForm)
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$store.commit('loading/PROCESSED')
                        this.shopifyError.state = false
                        this.shopifyError.message = exception.response.data.data
                    }
                }
            }
        },
    },
}
</script>
